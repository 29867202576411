import { TextField, Typography } from "@mui/material";
import React from "react";
import "../styles/Select.css"
import { QueryType, StringArrayFilters } from "./SearchWithProvider/types";

const formatValue = (v: string | number) => {
  if (v === '' || v === null || v === undefined) {
    return null;
  }
  let value: number | null = Number(v);
  if (Number.isNaN(value)) {
    value = null
  }
  return value;
}

const DEFAULT_MIN_MAX = ['', ''];

export type RangeSelectProps = {
  title: string,
  query: QueryType,
  attribute: keyof StringArrayFilters,
  constructRange: (min: number | null, max: number | null, attribute: string) => void
}

const ReverseRangeSelect = ({ title, query, attribute, constructRange }: RangeSelectProps) => {
  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [, max] = query.filters[attribute] ?? DEFAULT_MIN_MAX;

    const newMin = formatValue(event.target.value);
    const newMax = formatValue(max);

    constructRange(
      newMin,
      newMax,
      attribute
    );
  }

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [min] = query.filters[attribute] ?? DEFAULT_MIN_MAX;

    const newMin = formatValue(min);
    const newMax = formatValue(event.target.value);

    constructRange(
      newMin,
      newMax,
      attribute
    );
  }

  const [min, max] = query.filters[attribute] ?? DEFAULT_MIN_MAX;

  return (
    <>
      <Typography className="range-select-caption" variant="body1">
        {title}
      </Typography>
      <TextField
        type="number"
        size="small"
        variant="standard"
        label="High"
        onChange={handleMaxChange}
        value={max}
      />
      <TextField
        type="number"
        size="small"
        variant="standard"
        label="Low"
        onChange={handleMinChange}
        value={min}
      />
    </>
  )
}

export default ReverseRangeSelect;
