import AsyncMulti from "../../../AsyncMulti";
import Dropdown from "../../../../common/components/Dropdown";
import toolTips from "../../../toolTips";
import * as React from "react";
import { useSearchContext } from "../../store";
import FilterSection from "../../../FilterPanel/FilterSection";
import { Divider } from "@mui/material";
import ReverseRangeSelect from "src/components/ReverseRangeSelect";

const SampleFilterSection = () => {
  const {
    searchStore: {
      query
    },
    callAPI: {
      constructMulti,
      constructRange
    }
  } = useSearchContext()
  return (
    <FilterSection title="Sample Filters">
      <Dropdown title="Data Source/Project">
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="data_source"
          label="Data Source"
          tip={toolTips.data_source}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="project_name"
          label="Project Name"
          tip={toolTips.project_name}
        />
      </Dropdown>
      <Dropdown title="Collection Site">
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="country"
          label="Country"
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="state_province"
          label="State/Province"
          params={{ countries: query.filters.country }}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="site_type"
          label="Site Type"
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="section_name"
          label="Section Name"
          tip={toolTips.section_name}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="craton_terrane"
          label="Craton/Terrane"
          tip={toolTips.craton_terrane}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="basin_name"
          label="Basin Name"
          tip={toolTips.basin_name}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="basin_type"
          label="Basin Type"
          tip={toolTips.basin_type}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="meta_bin"
          label="Metamorphic Name"
          tip={toolTips.metamorphic_bin}
        />
        {query.type !== "analyses" &&
          <AsyncMulti
            onChange={constructMulti}
            query={query}
            attribute="collector"
            label="Collector"
            tip={toolTips.collector}
          />
        }
      </Dropdown>
      <Dropdown title="Geological and Stratigraphic Context">
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="strat_unit"
          label="Stratigraphic Unit"
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="environment_bin"
          label="Environmental Bin"
          tip={toolTips.environment_bin}
        />
        <div className="range-select-root">
          <ReverseRangeSelect
            constructRange={constructRange}
            attribute="interpreted_age"
            title="Interpreted Age in Ma (Older-Younger):  "
            query={query}
          />
        </div>
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="geol_age"
          label="Geological Age"
          tip={toolTips.geol_age}
        />
      </Dropdown>
      <Dropdown title="Lithology and Sample Type">
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="lithology_name"
          label="Lithology Name"
          tip={toolTips.lithology_name}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="lithology_text"
          label="Lithology Texture"
          tip={toolTips.lithology_text}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="lithology_comp"
          label="Lithology Composition"
          tip={toolTips.lithology_comp}
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="lithology_class"
          label="Lithology Class"
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="sample_type"
          label="Sample Type"
        />
      </Dropdown>
      <Divider>Search by</Divider>
      <AsyncMulti
        onChange={constructMulti}
        query={query}
        attribute="original_name"
        label="Original Name"
      />
      <AsyncMulti
        onChange={constructMulti}
        query={query}
        attribute="sample_id"
        label="Sample ID"
        params={{
          limit: 1,
          loadEmpty: false
        }}
      />
      {query.type === "analyses" && (
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="ana_code"
          label="Analyte Code"
        />
      )}
    </FilterSection>
  )
}
export default SampleFilterSection
