import React from "react";

export const defaultShow = {
  alternate_name: "alternate name",
  height_meters: "height/depth",
  section_name: "section name",
  site_type: "site type",
  sample_type: "sample type",
  country: "country",
  state_province: "state/province",
  site_desc: "site description",
  coord_lat: "site latitude",
  coord_long: "site longitude",
  craton_terrane: "craton/terrane",
  basin_name: "basin name",
  basin_type: "basin type",
  meta_bin: "metamorphic bin",
  earliest_date: "earliest collection time",
  latest_date: "latest collection time",
  collector: "collector",
  // geol_ctxt_notes: "geological context notes",
  // lithostrat_desc: "lithostratigraphic description",
  verb_strat_unit: "verbatim stratigraphic unit",
  // strat_name: "stratigraphy name",
  strat_unit: "stratigraphic unit",
  environment_bin: "environmental bin",
  environment_notes: "environment notes",
  // environment_verb: "verbatim environment",
  is_turbiditic: "turbiditic (t/f)",
  biostrat_verb: "verbatim biostratigraphy",
  // biozone_name: "biozone name",
  age_verb: "verbatim geological age",
  geol_age: "geological age",
  lithology_name: "lithology name",
  // lithology_type: "lithology type",
  lithology_class: "lithology class",
  lithology_text: "lithology texture",
  // lithology_text_desc: "lithology texture description",
  lithology_comp: "lithology composition",
  // lithology_comp_desc: "lithology composition description",
  lithology_notes: "lithology notes",
  color_name: "color",
  // color_qualifier: "color qualifier",
  // color_shade: "color shade",
  // munsell_code: "munsell code",
  fossil_verb: "verbatim fossil(s)",
  // fossil_name: "fossil name",
  sedstruct_name: "sedimentary structure(s)",
  // sedstruct_desc: "sedimentary structure description",
  interpreted_age: "interpreted age",
  interpreted_age_notes: "interpreted age justification",
  max_age: "max age",
  min_age: "min age",
  sample_notes: "sample notes",
  project_name: "project name",
  project_type: "project type",
  data_source: "data source",
  // data_source_desc: "data source description",
  traditional_territory: "traditional territory"
};

export const defaultShowAnalysis = {
  run_by: "run by",
  provider: "provided by",
  lab: "lab provider name",
  batch_id: "batch identifier",
  batch_lab_id: "lab batch identifier",
  geochem_method: "prep method",
  experiment_method_code: "experimental method code",
  experiment_method: "experimental method translation",
  analytical_method_code: "analytical method code",
  analytical_method: "analytical method translation",
  upper_detection: "upper detection limit",
  lower_detection: "lower detection limit",
  
};

export const defaultShowMethodology = {
  geochem_method: "prep method",
  experiment_method_code: "experimental method code",
  experiment_method: "experimental method translation",
  analytical_method_code: "analytical method code",
  analytical_method: "analytical method translation",
};

export const defaultShowReferences = {
  analysis_ref_long: "analysis reference (long)",
  analysis_ref_short: "analysis reference (short)",
  analysis_doi: "DOI"
};

export const analytesShow = {
  alternate_name: "alternate name",
  height_meters: "height/depth",
  section_name: "section name",
  site_type: "site type",
  country: "country",
  state_province: "state/province",
  site_desc: "site description",
  coord_lat: "site latitude",
  coord_long: "site longitude",
  craton_terrane: "craton/terrane",
  basin_name: "basin name",
  basin_type: "basin type",
  meta_bin: "metamorphic bin",
  earliest_date: "earliest collection time",
  latest_date: "latest collection time",
  collector: "collector",
  geol_ctxt_notes: "geological context notes",
  lithostrat_desc: "lithostratigraphic description",
  verb_strat_unit: "verbatim stratigraphic unit",
  strat_name: "stratigraphy name",
  strat_unit: "stratigraphic unit",
  environment_bin: "environmental bin",
  environment_notes: "environment notes",
  // environment_verb: "verbatim environment",
  is_turbiditic: "turbiditic (t/f)",
  biostrat_verb: "verbatim biostratigraphy",
  // biozone_name: "biozone name",
  age_verb: "verbatim geological age",
  geol_age: "geological age",
  lithology_name: "lithology name",
  // lithology_type: "lithology type",
  lithology_class: "lithology class",
  lithology_text: "lithology texture",
  lithology_text_desc: "lithology texture description",
  lithology_comp: "lithology composition",
  lithology_comp_desc: "lithology composition description",
  lithology_notes: "lithology notes",
  color_name: "color",
  color_qualifier: "color qualifier",
  color_shade: "color shade",
  // munsell_code: "munsell code",
  fossil_verb: "verbatim fossil(s)",
  // fossil_name: "fossil name",
  sedstruct_name: "sedimentary structure(s)",
  sedstruct_desc: "sedimentary structure description",
  interpreted_age: "interpreted age",
  interpreted_age_notes: "interpreted age justification",
  max_age: "max age",
  min_age: "min age",
  sample_notes: "sample notes",
  project_name: "project name",
  project_type: "project type",
  data_source: "data source",
  data_source_desc: "data source description",
  run_by: "run by",
  provider: "provided by",
  lab: "lab provider name",
  batch_id: "batch identifier",
  batch_lab_id: "lab batch identifier",
  geochem_method: "prep method",
  experiment_method_code: "experimental method code",
  experiment_method: "experimental method translation",
  analytical_method_code: "analytical method code",
  analytical_method: "analytical method translation",
  upper_detection: "upper detection limit",
  lower_detection: "lower detection limit",
  analysis_ref_long: "analysis reference (long)",
  analysis_ref_short: "analysis reference (short)"
};

// //iron and sulfur
export const iron_sulfur = {
  fe_carb_seq: "Fe-carb(seq) (wt%)",
  fe_mag_seq: "Fe-mag(seq) (wt%)",
  fe_ox_seq: "Fe-ox(seq) (wt%)",
  fe_py: "Fe-py (wt%)",
  fe: "Fe (wt%)",
  fe_hr: "FeHR (wt%)",
  fe_hr_fe_t: "FeHR/FeT",
  fe_py_fe_hr: "Fe-py/FeHR",
  fe_avs: "Fe-AVS (wt%)",
  fe_hcl: "Fe-HCl (wt%)",
  s: "S (wt%)",
  delta_s34_py: "δ34S-py (‰)",
  delta_s34_cas: "δ34S-cas (‰)",
  delta_s34_gyp: "δ34S-gyp (‰)",
  delta_s34_obs: "δ34S-obs (‰)",
  delta_s34: "δ34S-bulk (‰)",
  s_py: "S-py (wt%)",
  avs: "AVS (wt%)",
  s_org: "S-org (wt%)",
  s_so4: "S-SO4 (wt%)",
  cas: "CAS (ppm)"
};

//Carbon
export const carbon = {
  tot_c: "Total Carbon (wt%)",
  toc: "TOC (wt%)",
  tic: "TIC (wt%)",
  delta_c13_org: "δ13C-org (‰)",
  s1: "S1 (mgHC/g)",
  s2: "S2 (mgHC/g)",
  s3: "S3 (mgCO2/g)",
  tmax: "Tmax (degC)",
  hi: "HI",
  oi: "OI",
  pi: "PI"
};

// Carbonate Proxies
export const carbonate_proxy = {
  delta_c13_carb: "δ13C-carb (‰)",
  delta_o18_carb: "δ18O-carb (‰)",
  cap_delta_c13: "Δ13C (‰)",
  carb: "Carbonate (wt%)",
  ce_ce: "Ce/Ce*",
  ica_ratio: "I/Ca",
  icamg_ratio: "I/[Ca+Mg]",
  mg_ca: "Mg/Ca (wt%/wt%)",
  mn_ca: "Mn/Ca (ppm/wt%)",
  sr_ca: "Sr/Ca (ppm/wt%)",
  u_ca: "U/Ca (ppm/wt%)",
  mn_sr: "Mn/Sr (ppm/ppm)"
};


// Nitrogen and Phosphorous
export const nitrogen_phosphorous = {
  n: "N (wt%)",
  delta_n15: "δ15N (‰)",
  delta_n15_ker: "δ15N-ker (‰)",
  cn_ratio: "C:N (atomic)",
  p: "P (ppm)",
  p_fe1: "P-Fe1 (ppm)",
  p_auth: "P-auth (ppm)",
  p_det: "P-det (ppm)",
  p_mag: "P-mag (ppm)",
  p_fe2: "P-Fe2 (ppm)",
  p_org: "P-org (ppm)",
  p_inorg: "P-inorg (ppm)",
};

export const metalIsotope = {
  delta_ca44_ca40: "δ44/40Ca (‰)",
  delta_cr53: "δ53Cr (‰)",
  delta_cr53_auth: "δ53Cr-auth (‰)",
  delta_cu65: "δ65Cu (‰)",
  delta_fe56_auth: "δ56Fe-auth (‰)",
  delta_fe56_py: "δ56Fe-py (‰)",
  delta_fe56_t: "δ56Fe-T (‰)",
  delta_li7: "δ7Li (‰)",
  delta_mg26: "δ26Mg (‰)",
  delta_mo98: "δ98Mo (‰)",
  delta_mo98_auth: "δ98Mo-auth (‰)",
  delta_se82_se78: "δ82/78Se (‰)",
  delta_u238: "δ238U (‰)",
  delta_u238_auth: "δ238U-auth (‰)",
  delta_u238_carb: "δ238U-carb (‰)",
  delta_zn66: "δ66Zn (‰)",
  nd143_nd144: "Nd143/Nd144",
  sm147_nd144: "Sm147/Nd144",
  os_192: "192Os (ppt)",
  os187_os188: "Os187/Os188",
  os187_os188_i: "Osi",
  re187_os188: "Re187/Os188",
  sr87_sr86: "Sr87/Sr86",
  e_205tl: "𝜀205Tl",
  e_205tl_auth: "𝜀205Tl-auth",
  e_205tl_det: "𝜀205Tl-det",
  e_nd_0: "eNd(0)",
  e_nd_t: "eNd(t)",
  e_nd_i: "𝜀Ndi",
};
export const metalIsotopeTip = {
  label: "auth, carb, and total metal isotopes",
  description: (<>Trace metal isotopes are generally tracked as three phases or targeted types: <b>auth</b> (authigenic, from a light acid leach on shales, targeting metals associated with organic matter or pyrite), <b>carb</b> (carbonate, from a light acid leach on carbonates, targeting metals in the carbonate crystal lattice), and <b>total</b> (metal isotopes in all phases including detrital, resulting from total digestion of the rock. Total isotope measurements do not have any suffix).</>)
}

export const customElements = {
  ag: "Ag (ppm)",
  alu: "Al (wt%)",
  ars: "As (ppm)",
  au: "Au (ppb)",
  b: "B (ppm)",
  ba: "Ba (ppm)",
  be: "Be (ppm)",
  bi: "Bi (ppm)",
  ca: "Ca (wt%)",
  cd: "Cd (ppm)",
  ce: "Ce (ppm)",
  co: "Co (ppm)",
  cr: "Cr (ppm)",
  cs: "Cs (ppm)",
  cu: "Cu (ppm)",
  dy: "Dy (ppm)",
  er: "Er (ppm)",
  eu: "Eu (ppm)",
  fe: "Fe (wt%)",
  ga: "Ga (ppm)",
  gd: "Gd (ppm)",
  ge: "Ge (ppm)",
  hf: "Hf (ppm)",
  hg: "Hg (ppm)",
  ho: "Ho (ppm)",
  in: "In (ppm)",
  ir: "Ir (ppm)",
  k: "K (wt%)",
  la: "La (ppm)",
  li: "Li (ppm)",
  lu: "Lu (ppm)",
  mg: "Mg (wt%)",
  mn: "Mn (ppm)",
  mo: "Mo (ppm)",
  n: "N (wt%)",
  na: "Na (wt%)",
  nb: "Nb (ppm)",
  nd: "Nd (ppm)",
  ni: "Ni (ppm)",
  os: "Os (ppt)",
  p: "P (ppm)",
  pb: "Pb (ppm)",
  pd: "Pd (ppb)",
  pr: "Pr (ppm)",
  pt: "Pt (ppb)",
  rb: "Rb (ppm)",
  re: "Re (ppm)",
  s: "S (wt%)",
  sb: "Sb (ppm)",
  sc: "Sc (ppm)",
  se: "Se (ppm)",
  si: "Si (wt%)",
  sm: "Sm (ppm)",
  sn: "Sn (ppm)",
  sr: "Sr (ppm)",
  ta: "Ta (ppm)",
  tb: "Tb (ppm)",
  te: "Te (ppm)",
  th: "Th (ppm)",
  ti: "Ti (wt%)",
  tl: "Tl (ppm)",
  tm: "Tm (ppm)",
  u: "U (ppm)",
  v: "V (ppm)",
  w: "W (ppm)",
  y: "Y (ppm)",
  yb: "Yb (ppm)",
  zn: "Zn (ppm)",
  zr: "Zr (ppm)",
  ree: "REE (ppm)",
  lree: "LREE (ppm)",
  mree: "MREE (ppm)",
  hree: "HREE (ppm)",
  rey: "REY (ppm)"
};

export const mineralogy_composition = {
one_to_one_clays: "1:1 clays (wt%)",
two_to_one_clays: "2:1 clays (wt%)",
albite: "Albite (wt%)",
analcime: "Analcime (wt%)",
anatase: "Anatase (wt%)",
anhydrite: "Anhydrite (wt%)",
ankerite: "Ankerite (wt%)",
anorthite: "Anorthite (wt%)",
apatite: "Apatite (wt%)",
aragonite: "Aragonite (wt%)",
ash: "Ash (wtpct)",
barite: "Barite (wt%)",
berthierine: "Berthierine (wt%)",
biotite: "Biotite (wt%)",
calcite: "Calcite (wt%)",
chamosite: "Chamosite (wt%)",
chlorite: "Chlorite (wt%)",
clinochlore: "Clinochlore (wt%)",
cristobalite: "Cristobalite (wt%)",
detritals: "Detritals (wt%)",
dolomite: "Dolomite (wt%)",
dolomite_iron_dolomite: "Dolomite_Iron_Dolomite (wt%)",
dolomite_siderite: "Dolomite_Siderite (wt%)",
fe_mg_mica: "Fe/Mg-mica (wt%)",
feldspar: "Feldspar (wt%)",
fluorapatite: "Fluorapatite (wt%)",
glauconite: "Glauconite (wt%)",
gypsum: "Gypsum (wt%)",
halite: "Halite (wt%)",
hematite: "Hematite (wt%)",
hydroxylapatite: "Hydroxylapatite (wt%)",
illite: "Illite (wt%)",
illite_mica: "Illite_Mica (wt%)",
illite_montmorillonite: "Illite_Montmorillonite (wt%)",
illite_muscovite: "Illite_Muscovite (wt%)",
illite_smectite: "Illite_Smectite (wt%)",
ilmenite: "Ilmenite (wt%)",
jarosite: "Jarosite (wt%)",
k_feldspar: "K-Feldspar (wt%)",
k_al_mica: "K/Al-mica (wt%)",
k_mg_mica: "K/Mg-mica (wt%)",
kaolinite: "Kaolinite (wt%)",
magnesite: "Magnesite (wt%)",
magnetite: "Magnetite (wt%)",
marcasite: "Marcasite (wt%)",
mica: "Mica (wt%)",
microcline: "Microcline (wt%)",
mixed_layer_clays: "Mixed_Layer_Clays (wt%)",
montmorillonite: "Montmorillonite (wt%)",
muscovite: "Muscovite (wt%)",
nontronite: "Nontronite (wt%)",
orthoclase: "Orthoclase (wt%)",
plagioclase: "Plagioclase (wt%)",
pyrite: "Pyrite (wt%)",
pyrrhotite: "Pyrrhotite (wt%)",
quartz: "Quartz (wt%)",
quartz_christobalite: "Quartz (w/christobalite) (wt%)",
rhodochrosite: "Rhodochrosite (wt%)",
rutile: "Rutile (wt%)",
sanidine: "Sanidine (wt%)",
siderite: "Siderite (wt%)",
smectite: "Smectite (wt%)",
sphalerite: "Sphalerite (wt%)",
talc: "Talc (wt%)",
total_carbonate: "Total_Carbonate (wt%)",
total_clay: "Total_Clay (wt%)",
total_nonclay: "Total_NonClay (wt%)",
insol: "Insol (wt%)",
loi: "LOI (wt%)",
sum: "Sum (wt%)",
cia: "CIA"
}

export const AttributesShowTooltip = {
  ce_ce: "Ce anomalies are reported as a published proxy in a paper and currently do not distinguish between calculation methods (i.e., whether and how Ce is compared to La, Pr, and Nd).",
  cia: "CIA (Chemical Index of Alteration) values are reported as a published proxy in a paper and currently do not distinguish between calculation methods."
}
