import React from 'react';
import "../../styles/Documentation.css";
import { CONFIG_CONSTANTS } from '../../constants';
import { Link, Typography } from '@mui/material';

const PublicAPIDescription = () => {
    const apiDocsPath = `${CONFIG_CONSTANTS.API_HOST_PATH}/api-docs`;

    return (
        <div className="documentation-page">
            <Typography variant='h4' className="documentation-contents-header">Public API Description</Typography>
            <div>
                <Typography variant="body2" paragraph={true}>
                    As can be seen be seen with the side pane’s API button, the frontend
                    uses the SGP public API to query the SGP database. The frontend is
                    great for users who want to get a feel for the potential of SGP
                    search services but is rather limited when it comes to the number of
                    available filter search options. To truly take advantage of the
                    plethora of searchable attributes that exist in the SGP database,
                    users are encouraged to familiarize themselves with the public API.
                    When using the public API, the user must first construct a JSON API
                    call using the syntax explained below and then communicate their
                    requests to the SGP backend using an HTTP client (e.g. Postman API
                    development environment, curl command line tool. There are also
                    libraries that simplify and automate this process in different
                    programming languages). Users should send their API calls to the
                    following URL using an HTTP POST method:
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    <Link href="#">{`${CONFIG_CONSTANTS.API_HOST_PATH}/api/v1/post`}</Link>
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    An SGP API request consists of three parts which are analogous to
                    those found in an SGP frontend search:
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    <ul>
                        <li>
                            type: One search option for selecting the search type (samples,
                            analyses or nhhxrf)
                        </li>
                        <li>
                            filters: Contains a list search options which are logically
                            ANDed in the results
                        </li>
                        <li>
                            show: Contains search options that determine which columns will
                            appear in the results
                        </li>
                    </ul>
                </Typography>

                <Typography variant="body2" paragraph={true}>
                    Using the JSON data interchange format, a user can pass their search
                    option parameters within the body of the HTTP Post packet. Below is
                    an example SGP API call:
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    {JSON.stringify({
                        type: "samples",
                        filters: {
                            country: ["Argentina", "Canada", "Oman"],
                            fe: [2, 4],
                            fe_carb_seq: [0.025, 0.05]
                        },
                        show: [
                            "country",
                            "fe",
                            "fe_carb_seq",
                            "section_name",
                            "height_meters"
                        ]
                    })}
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    This API call is making a samples type search for samples that
                    originate from Argentina, Canada or Oman, have 2%-4% Fe and
                    0.025%-0.05% Fe-Carb. In addition, the API call is asking for
                    columns that show each sample’s country, Fe (wt%), Fe-Carb(seq) (wt%),
                    section name and collection height in meters. Each search attribute
                    (i.e. country, fe, section_name etc.) has an single word API name, a
                    set of search types in which it can be searched upon and only
                    accepts certain search parameter types which are listed below:
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    <ul>
                        <li>
                            String: tells the backend to find results matching a single
                            given string (e.g. country : "Canada")
                        </li>
                        <li>
                            String Array: requires that a sample/analysis matches at least
                            one of given strings (e.g. country : ["Argentina", "Canada",
                            "Oman"])
                        </li>
                        <li>
                            Number: asks for search matches to have an exact value match
                            with the given number (e.g. fe : 2.14)
                        </li>
                        <li>
                            Number Range: will match with any samples/analysis that contain
                            a value in the given range (e.g. fe : [2, 4.5])
                        </li>
                    </ul>
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    You can follow the link to see Open API (Swagger) documentation page:
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    <Link href={apiDocsPath} target="_blank">{apiDocsPath}</Link>
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    With this guide and the search attribute dictionary below, users now
                    have the power to sift through the SGP database in any way they
                    please. We hope this documentation has been helpful, and appreciate
                    any feedback you can give us through <Link href="mailto:esper@stanford.edu">esper@stanford.edu</Link>.
                </Typography>
            </div>
        </div>
    );
}
export default PublicAPIDescription;