import { Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const SearchAttributes = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">Search Attributes Dictionary</Typography>
        <div>
            <Typography variant="body2" paragraph={true}>
                The table below presents all searchable attributes that currently
                exist in the SGP database. Here are descriptions for each column:
            </Typography>
            <Typography variant="body2" paragraph={true}>
                <ul>
                    <li>
                        Attribute API: This is the single word string that is used in
                        API calls to refer to the attribute.
                    </li>
                    <li>
                        Attribute ID: This human readable string identifies the
                        attribute and is used as column titles in search results.
                    </li>
                    <li>
                        Search Types: This lists the search types within which the
                        attribute can be filtered through and/or shown.
                    </li>
                    <li>
                        Parameter Types: This lists the legal parameter types the search
                        attributes accepts during filtering.
                    </li>
                    <li>
                        Geology Description: This optional column may contain additional
                        descriptions that are important from a semantic standpoint.
                    </li>
                </ul>
            </Typography>
        </div>
        <table className="documentation-attributes-table">
            <tr>
                <td>Attribute API</td>
                <td>Attribute ID</td>
                <td>Search Types</td>
                <td>Parameter Types</td>
                <td>Geology Description</td>
            </tr>
            <tr>
                <td>sample_id</td>
                <td>sample identifier</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>original_name</td>
                <td>sample original name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>analysis_id</td>
                <td>analysis result identifier</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>ana_code</td>
                <td>analyte code</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>abundance</td>
                <td>analyte abundance</td>
                <td>analyses</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>unit_used</td>
                <td>unit</td>
                <td>analyses</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>alternate_name</td>
                <td>alternate name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Additional sample names this sample may have been published as
                </td>
            </tr>
            <tr>
                <td>height_meters</td>
                <td>height/depth</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td>Stratigraphic height in meters or depth in drillcore</td>
            </tr>
            <tr>
                <td>section_name</td>
                <td>section name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    This could be a drillcore, stratigraphic section, or
                    spot-sampling locality. In some rare instances where complete
                    information could be not obtained, a best-guess sampling
                    locality was created (for instance, the geographic center of
                    New York state if the only geographical information was 'New
                    York State').'.
                </td>
            </tr>
            <tr>
                <td>site_type</td>
                <td>site type</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>Outcrop, drillcore, cuttings, or modern sediments</td>
            </tr>
            <tr>
                <td>country</td>
                <td>country</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>state_province</td>
                <td>state/province</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>site_desc</td>
                <td>site description</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Any details or descriptions of the site - e.g. alternate
                    names, location information (5 miles west of X, by the creek,
                    etc.)
                </td>
            </tr>
            <tr>
                <td>coord_lat</td>
                <td>site latitude</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>coord_long</td>
                <td>site longitude</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>craton_terrane</td>
                <td>craton/terrane</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    The name of the craton or terrane e.g. Laurentia, Avalonia. As
                    entered by SGP collaborative team members
                </td>
            </tr>
            <tr>
                <td>basin_name</td>
                <td>basin name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    The name of the sedimentary basin e.g. Taconic Foreland Basin.
                    As entered by SGP collaborative team members
                </td>
            </tr>
            <tr>
                <td>basin_type</td>
                <td>basin type</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>The type of basin e.g. peripheral foreland, rift, etc.</td>
            </tr>
            <tr>
                <td>meta_bin</td>
                <td>metamorphic bin</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Sites are sorted into three low-grade metamorphic bins,
                    roughly based on metapelite zones as follows: 1) Diagenetic
                  zone. Under mature, preserved biomarkers, KI {">"} 0.42, CAI{" "}
                    {"≤"} 3, Ro {"<"} 2.0, facies: zeolite-subgreenschist facies,
                  grade: diagenesis-very low grade 2) Anchizone. Over-mature, no
                  preserved biomarkers, CAI=4, Ro 2-4, facies: sub-greenschist,
                  grade: very low grade 3) Epizone. Ro{">"}4, CAI=5, KI {"<"}{" "}
                  0.25, facies: greenschist, grade: low-grade
                </td>
            </tr>
            <tr>
                <td>earliest_date</td>
                <td>earliest collection time</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>latest_date</td>
                <td>latest collection time</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>collector</td>
                <td>collector full name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>collector_first</td>
                <td>collector first name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>collector_last</td>
                <td>collector last name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>geol_ctxt_notes</td>
                <td>geological context notes</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Any clarification or comments on site-level features. This
                    field often includes error estimates on latitudes/longitudes.
                </td>
            </tr>
            <tr>
                <td>lithostrat_desc</td>
                <td>lithostratigraphic description</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>General lithological description of the unit</td>
            </tr>
            <tr>
                <td>verb_strat_unit</td>
                <td>verbatim stratigraphic unit</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    The verbatim lithostratigraphy as described in a paper or by
                    SGP collaborative team member. This may contain additional
                    nuance or regional information compared to the formal
                    lithostratigraphy (e.g. upper middle Frankfort Shale)
                </td>
            </tr>
            <tr>
                <td>strat_name</td>
                <td>stratigraphy name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Short name of the unit, often without 'Formation' or 'Member'
                </td>
            </tr>
            <tr>
                <td>strat_unit</td>
                <td>stratigraphic unit</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>environment_bin</td>
                <td>environmental bin</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Environments are divided into five bins - inner shelf/outer
                    shelf/basinal/lacustrine/fluvial - according to the following
                    criteria (1-3 from Sperling et al. 2015). 1) Environment
                    1/Inner Shelf: Shale interbedded with abundant shallow-water
                    indicators. This includes clastic beds with wave-generated
                    sedimentary structures as well as shallow-water carbonates
                    such as stromatolites, oolites, and rip-up conglomerates.
                    Evidence of exposure—i.e. mudcracks, karsting, teepee
                    structures—are often in relatively close stratigraphic
                    proximity on the meters to 10s of meters scale. 2) Environment
                    2/Outer Shelf: Shale from sequences that generally show little
                    wave activity, but with occasional evidence for storm and/or
                    wave activity, such as hummocky cross-stratified sands encased
                    in shales. Evidence for exposure is not in close stratigraphic
                    proximity. 3) Environment 3/Basinal: Shale from successions
                    with no evidence for any storm and/or wave activity for an
                    appreciable (i.e. &gt;50 m) stratigraphic distance. Generally
                    located considerably basin-ward of shallower-water facies. 4)
                    Lacustrine 5) Fluvial
                </td>
            </tr>
            <tr>
                <td>environment_notes</td>
                <td>environment notes</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>environment_verb</td>
                <td>verbatim environment</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    The verbatim environment as described in a paper or by SGP
                    collaborative team member. This may contain additional nuance
                    or regional information compared to the binned classification
                </td>
            </tr>
            <tr>
                <td>is_turbiditic</td>
                <td>turbiditic (t/f)</td>
                <td>analyses, samples</td>
                <td>N/A</td>
                <td>
                    Whether the environment was turbiditic. This is a general
                    feature of the stratigraphic package rather than a distinction
                    between sampling Te layers versus the entire turbidite. Note
                    that many samples do not have this information filled out.
                </td>
            </tr>
            <tr>
                <td>biostrat_verb</td>
                <td>verbatim biostratigraphy</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>biozone_name</td>
                <td>biozone name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>age_verb</td>
                <td>verbatim geological age</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Verbatim age as reported in publication or by SGP
                    collaborative team member. This may often include regional age
                    schemes.
                </td>
            </tr>
            <tr>
                <td>geol_age</td>
                <td>geological age</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>International Commission on Stratigraphy age names</td>
            </tr>
            <tr>
                <td>lithology_name</td>
                <td>lithology name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>The primary sediment type of the sample</td>
            </tr>
            <tr>
                <td>lithology_type</td>
                <td>lithology type</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>lithology_class</td>
                <td>lithology class</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>lithology_text</td>
                <td>lithology texture</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>Textural modifiers (siltey, muddy, sandy, clayey)</td>
            </tr>
            <tr>
                <td>lithology_text_desc</td>
                <td>lithology texture description</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>lithology_comp</td>
                <td>lithology composition</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Compositional modifiers (e.g., phosphatic, calcareous,
                    dolomitic, etc.)
                </td>
            </tr>
            <tr>
                <td>lithology_comp_desc</td>
                <td>lithology composition description</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>lithology_notes</td>
                <td>lithology notes</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>color_name</td>
                <td>color</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>color_qualifier</td>
                <td>color qualifier</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>color_shade</td>
                <td>color shade</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>munsell_code</td>
                <td>munsell code</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>fossil_verb</td>
                <td>verbatim fossil(s)</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>fossil_name</td>
                <td>fossil name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>fossil_pbdb</td>
                <td>PBDB code</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>sedstruct_name</td>
                <td>sedimentary structure name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>sedstruct_desc</td>
                <td>sedimentary structure description</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>interpreted_age</td>
                <td>interpreted age</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td>
                    A numerical estimate for the age of the sample in millions of
                    years
                </td>
            </tr>
            <tr>
                <td>interpreted_age_notes</td>
                <td>interpreted age justification</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Justification for the numerical estimate given in
                    interpretative age, as provided by SGP collaborative team
                    members. Uncertainty on the age estimate is often also
                    included
                </td>
            </tr>
            <tr>
                <td>max_age</td>
                <td>max age</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td>
                    Maximum age of the sample, as provided by SGP collaborative
                    team members
                </td>
            </tr>
            <tr>
                <td>min_age</td>
                <td>min age</td>
                <td>analyses, samples</td>
                <td>Number, Number Range</td>
                <td>
                    Minimum age of the sample, as provided by SGP collaborative
                    team members
                </td>
            </tr>
            <tr>
                <td>sample_notes</td>
                <td>sample notes</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>project_name</td>
                <td>project name</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    SGP data are organized as 'Projects.' In most cases, these
                    represent the data contained in a published paper. However, in
                    some cases they may represent groupings of papers, data from a
                    particular region, or samples run by a particular person or
                    lab.
                </td>
            </tr>
            <tr>
                <td>project_type</td>
                <td>project type</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>data_source</td>
                <td>data source</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Data in the SGP database come from three main data sources: 1)
                    information provided by the SGP collaborative team, 2) legacy
                    USGS data from the National Geochemical Database, 3) data
                    compiled by the USGS Critical Metals In Black Shales project
                    (CMIBS),  4) data from the Australian OZCHEM database, 5) data from the Alberta Geological Survey (AGS).
                </td>
            </tr>
            <tr>
                <td>data_source_desc</td>
                <td>data source description</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>traditional_territory</td>
                <td>traditional territory</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>run_by</td>
                <td>run by full name</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>run_by_first</td>
                <td>run by first name</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>prov_by_last</td>
                <td>provided by last name</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>provider</td>
                <td>provided by full name</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>provider_first</td>
                <td>provided by first name</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>provider_last</td>
                <td>provided by last name</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>lab</td>
                <td>lab</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>batch_id</td>
                <td>batch identifier</td>
                <td>analyses</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>batch_lab_id</td>
                <td>lab batch identifier</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>geochem_method</td>
                <td>prep method</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    How the sample was prepared to a powder (e.g. tungsten carbide
                    shatterbox, agate mortar and pestle)
                </td>
            </tr>
            <tr>
                <td>experiment_method_code</td>
                <td>experimental method code</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>experiment_method</td>
                <td>experimental method translation</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>analytical_method_code</td>
                <td>analytical method code</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td>
                    Code for analytical method e.g. XRF, ICP:MS etc. Based in part
                    on the list used by EarthChem
                </td>
            </tr>
            <tr>
                <td>analytical_method</td>
                <td>analytical method translation</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td />
            </tr>
            <tr>
                <td>upper_detection</td>
                <td>upper detection limit</td>
                <td>analyses</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>lower_detection</td>
                <td>lower detection limit</td>
                <td>analyses</td>
                <td>Number, Number Range</td>
                <td />
            </tr>
            <tr>
                <td>analysis_ref_long</td>
                <td>analysis reference (long)</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td>Full reference for the analysis</td>
            </tr>
            <tr>
                <td>analysis_ref_short</td>
                <td>analysis reference (short)</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td>Short-hand reference (e.g. Sperling, 2013)</td>
            </tr>
            <tr>
                <td>analysis_doi</td>
                <td>DOI</td>
                <td>analyses</td>
                <td>String, String Array</td>
                <td>DOI</td>
            </tr>
            <tr>
                <td>fe_carb_seq</td>
                <td>Fe-carb(seq) (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_mag_seq</td>
                <td>Fe-mag(seq) (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_ox_seq</td>
                <td>Fe-ox(seq) (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_py</td>
                <td>Fe-py (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe</td>
                <td>Fe (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_hr</td>
                <td>FeHR (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_hr_fe_t</td>
                <td>FeHR/FeT</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_py_fe_hr</td>
                <td>Fe-py/FeHR</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_avs</td>
                <td>Fe-AVS (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_hcl</td>
                <td>Fe-HCl (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s</td>
                <td>S (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_s34_py</td>
                <td>δ34S-py (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_s34_cas</td>
                <td>δ34S-cas (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_s34_gyp</td>
                <td>δ34S-gyp (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_s34_obs</td>
                <td>δ34S-obs (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_s34</td>
                <td>δ34S-bulk (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s_py</td>
                <td>S-py (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>avs</td>
                <td>AVS (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s_org</td>
                <td>S-org (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s_so4</td>
                <td>S-SO4 (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cas</td>
                <td>CAS (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>tot_c</td>
                <td>Total Carbon (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>toc</td>
                <td>TOC (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>tic</td>
                <td>TIC (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_c13_org</td>
                <td>δ13C-org (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s1</td>
                <td>S1 (mgHC/g)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s2</td>
                <td>S2 (mgHC/g)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s3</td>
                <td>S3 (mgCO2/g)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>tmax</td>
                <td>Tmax (degC)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>hi</td>
                <td>HI</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>oi</td>
                <td>OI</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pi</td>
                <td>PI</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_c13_carb</td>
                <td>δ13C-carb (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_o18_carb</td>
                <td>δ18O-carb (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cap_delta_c13</td>
                <td>Δ13C (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>carb</td>
                <td>Carbonate (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ce_ce</td>
                <td>Ce/Ce*</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ica_ratio</td>
                <td>I/Ca</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>icamg_ratio</td>
                <td>I/[Ca+Mg]</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mg_ca</td>
                <td>Mg/Ca (wt%/wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mn_ca</td>
                <td>Mn/Ca (ppm/wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sr_ca</td>
                <td>Sr/Ca (ppm/wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>u_ca</td>
                <td>U/Ca (ppm/wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mn_sr</td>
                <td>Mn/Sr (ppm/ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>n</td>
                <td>N (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_n15</td>
                <td>δ15N (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_n15_ker</td>
                <td>δ15N-ker (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cn_ratio</td>
                <td>C:N (atomic)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p</td>
                <td>P (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_fe1</td>
                <td>P-Fe1 (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_auth</td>
                <td>P-auth (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_det</td>
                <td>P-det (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_mag</td>
                <td>P-mag (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_fe2</td>
                <td>P-Fe2 (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_org</td>
                <td>P-org (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p_inorg</td>
                <td>P-inorg (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_ca44_ca40</td>
                <td>δ44/40Ca (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_cr53</td>
                <td>δ53Cr (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_cr53_auth</td>
                <td>δ53Cr-auth (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_cu65</td>
                <td>δ65Cu (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_fe56_auth</td>
                <td>δ56Fe-auth (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_fe56_py</td>
                <td>δ56Fe-py (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_fe56_t</td>
                <td>δ56Fe-T (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_li7</td>
                <td>δ7Li (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_mg26</td>
                <td>δ26Mg (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_mo98</td>
                <td>δ98Mo (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_mo98_auth</td>
                <td>δ98Mo-auth (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_se82_se78</td>
                <td>δ82/78Se (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_u238</td>
                <td>δ238U (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_u238_auth</td>
                <td>δ238U-auth (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_u238_carb</td>
                <td>δ238U-carb (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>delta_zn66</td>
                <td>δ66Zn (‰)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>nd143_nd144</td>
                <td>Nd143/Nd144</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sm147_nd144</td>
                <td>Sm147/Nd144</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>os_192</td>
                <td>192Os (ppt)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>os187_os188</td>
                <td>Os187/Os188</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>os187_os188_i</td>
                <td>Osi</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>re187_os188</td>
                <td>Re187/Os188</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sr87_sr86</td>
                <td>Sr87/Sr86</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>e_205tl</td>
                <td>𝜀205Tl</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>e_205tl_auth</td>
                <td>𝜀205Tl-auth</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>e_205tl_det</td>
                <td>𝜀205Tl-det</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>e_nd_0</td>
                <td>eNd(0)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>e_nd_t</td>
                <td>eNd(t)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>e_nd_i</td>
                <td>𝜀Ndi</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ag</td>
                <td>Ag (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>alu</td>
                <td>Al (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ars</td>
                <td>As (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>au</td>
                <td>Au (ppb)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>b</td>
                <td>B (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ba</td>
                <td>Ba (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>be</td>
                <td>Be (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>bi</td>
                <td>Bi (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ca</td>
                <td>Ca (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cd</td>
                <td>Cd (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ce</td>
                <td>Ce (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>co</td>
                <td>Co (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cr</td>
                <td>Cr (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cs</td>
                <td>Cs (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cu</td>
                <td>Cu (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>dy</td>
                <td>Dy (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>er</td>
                <td>Er (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>eu</td>
                <td>Eu (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe</td>
                <td>Fe (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ga</td>
                <td>Ga (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>gd</td>
                <td>Gd (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ge</td>
                <td>Ge (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>hf</td>
                <td>Hf (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>hg</td>
                <td>Hg (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ho</td>
                <td>Ho (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>in</td>
                <td>In (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ir</td>
                <td>Ir (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>k</td>
                <td>K (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>la</td>
                <td>La (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>li</td>
                <td>Li (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>lu</td>
                <td>Lu (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mg</td>
                <td>Mg (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mn</td>
                <td>Mn (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mo</td>
                <td>Mo (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>n</td>
                <td>N (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>na</td>
                <td>Na (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>nb</td>
                <td>Nb (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>nd</td>
                <td>Nd (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ni</td>
                <td>Ni (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>os</td>
                <td>Os (ppt)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>p</td>
                <td>P (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pb</td>
                <td>Pb (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pd</td>
                <td>Pd (ppb)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pr</td>
                <td>Pr (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pt</td>
                <td>Pt (ppb)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>rb</td>
                <td>Rb (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>re</td>
                <td>Re (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>s</td>
                <td>S (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sb</td>
                <td>Sb (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sc</td>
                <td>Sc (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>se</td>
                <td>Se (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>si</td>
                <td>Si (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sm</td>
                <td>Sm (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sn</td>
                <td>Sn (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sr</td>
                <td>Sr (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ta</td>
                <td>Ta (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>tb</td>
                <td>Tb (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>te</td>
                <td>Te (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>th</td>
                <td>Th (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ti</td>
                <td>Ti (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>tl</td>
                <td>Tl (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>tm</td>
                <td>Tm (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>u</td>
                <td>U (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>v</td>
                <td>V (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>w</td>
                <td>W (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>y</td>
                <td>Y (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>yb</td>
                <td>Yb (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>zn</td>
                <td>Zn (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>zr</td>
                <td>Zr (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ree</td>
                <td>REE (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>lree</td>
                <td>LREE (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mree</td>
                <td>MREE (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>hree</td>
                <td>HREE (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>rey</td>
                <td>REY (ppm)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>one_to_one_clays</td>
                <td>1:1 clays (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>two_to_one_clays</td>
                <td>2:1 clays (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>albite</td>
                <td>Albite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>analcime</td>
                <td>Analcime (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>anatase</td>
                <td>Anatase (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>anhydrite</td>
                <td>Anhydrite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ankerite</td>
                <td>Ankerite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>anorthite</td>
                <td>Anorthite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>apatite</td>
                <td>Apatite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>aragonite</td>
                <td>Aragonite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ash</td>
                <td>Ash (wtpct)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>barite</td>
                <td>Barite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>berthierine</td>
                <td>Berthierine (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>biotite</td>
                <td>Biotite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>calcite</td>
                <td>Calcite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>chamosite</td>
                <td>Chamosite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>chlorite</td>
                <td>Chlorite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>clinochlore</td>
                <td>Clinochlore (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cristobalite</td>
                <td>Cristobalite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>detritals</td>
                <td>Detritals (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>dolomite</td>
                <td>Dolomite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>dolomite_iron_dolomite</td>
                <td>Dolomite_Iron_Dolomite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>dolomite_siderite</td>
                <td>Dolomite_Siderite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fe_mg_mica</td>
                <td>Fe/Mg-mica (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>feldspar</td>
                <td>Feldspar (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>fluorapatite</td>
                <td>Fluorapatite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>glauconite</td>
                <td>Glauconite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>gypsum</td>
                <td>Gypsum (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>halite</td>
                <td>Halite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>hematite</td>
                <td>Hematite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>hydroxylapatite</td>
                <td>Hydroxylapatite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>illite</td>
                <td>Illite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>illite_mica</td>
                <td>Illite_Mica (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>illite_montmorillonite</td>
                <td>Illite_Montmorillonite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>illite_muscovite</td>
                <td>Illite_Muscovite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>illite_smectite</td>
                <td>Illite_Smectite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>ilmenite</td>
                <td>Ilmenite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>jarosite</td>
                <td>Jarosite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>k_feldspar</td>
                <td>K-Feldspar (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>k_al_mica</td>
                <td>K/Al-mica (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>k_mg_mica</td>
                <td>K/Mg-mica (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>kaolinite</td>
                <td>Kaolinite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>magnesite</td>
                <td>Magnesite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>magnetite</td>
                <td>Magnetite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>marcasite</td>
                <td>Marcasite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mica</td>
                <td>Mica (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>microcline</td>
                <td>Microcline (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>mixed_layer_clays</td>
                <td>Mixed_Layer_Clays (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>montmorillonite</td>
                <td>Montmorillonite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>muscovite</td>
                <td>Muscovite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>nontronite</td>
                <td>Nontronite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>orthoclase</td>
                <td>Orthoclase (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>plagioclase</td>
                <td>Plagioclase (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pyrite</td>
                <td>Pyrite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>pyrrhotite</td>
                <td>Pyrrhotite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>quartz</td>
                <td>Quartz (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>quartz_christobalite</td>
                <td>Quartz (w/christobalite) (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>rhodochrosite</td>
                <td>Rhodochrosite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>rutile</td>
                <td>Rutile (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sanidine</td>
                <td>Sanidine (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>siderite</td>
                <td>Siderite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>smectite</td>
                <td>Smectite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sphalerite</td>
                <td>Sphalerite (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>talc</td>
                <td>Talc (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>total_carbonate</td>
                <td>Total_Carbonate (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>total_clay</td>
                <td>Total_Clay (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>total_nonclay</td>
                <td>Total_NonClay (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>insol</td>
                <td>Insol (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>loi</td>
                <td>LOI (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sum</td>
                <td>Sum (wt%)</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>cia</td>
                <td>CIA</td>
                <td>samples</td>
                <td>Number, Number Range</td>
                <td/>
            </tr>
            <tr>
                <td>sample_type</td>
                <td>Sample type</td>
                <td>analyses, samples</td>
                <td>String, String Array</td>
                <td/>
            </tr>
        </table>
    </div>
);

export default SearchAttributes;



